// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-do-pobrania-index-js": () => import("./../../../src/pages/do-pobrania/index.js" /* webpackChunkName: "component---src-pages-do-pobrania-index-js" */),
  "component---src-pages-galeria-index-js": () => import("./../../../src/pages/galeria/index.js" /* webpackChunkName: "component---src-pages-galeria-index-js" */),
  "component---src-pages-galeria-jesien-index-js": () => import("./../../../src/pages/galeria/jesien/index.js" /* webpackChunkName: "component---src-pages-galeria-jesien-index-js" */),
  "component---src-pages-galeria-lato-index-js": () => import("./../../../src/pages/galeria/lato/index.js" /* webpackChunkName: "component---src-pages-galeria-lato-index-js" */),
  "component---src-pages-galeria-wiosna-index-js": () => import("./../../../src/pages/galeria/wiosna/index.js" /* webpackChunkName: "component---src-pages-galeria-wiosna-index-js" */),
  "component---src-pages-galeria-zima-index-js": () => import("./../../../src/pages/galeria/zima/index.js" /* webpackChunkName: "component---src-pages-galeria-zima-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-dziekujemy-js": () => import("./../../../src/pages/kontakt/dziekujemy.js" /* webpackChunkName: "component---src-pages-kontakt-dziekujemy-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-przyjaciele-index-js": () => import("./../../../src/pages/przyjaciele/index.js" /* webpackChunkName: "component---src-pages-przyjaciele-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-zyciorys-index-js": () => import("./../../../src/pages/zyciorys/index.js" /* webpackChunkName: "component---src-pages-zyciorys-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

